.m9mpf7v{position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,0.6);z-index:var(--m9mpf7v-1);overflow:auto;}
.m13x1w5x{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:calc(100% - 32px);height:478px;background-color:var(--modalBackgroundColor);border-radius:12px;overflow:auto;z-index:2;}@media (min-width:390px){.m13x1w5x{height:685px;}}@media (min-width:460px){.m13x1w5x{width:calc(100% - 48px);}}@media (min-width:768px){.m13x1w5x{width:680px;}}@media (min-width:1200px){.m13x1w5x{width:780px;height:760px;}}
.mbcbe4q{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;margin-right:16px;padding:16px;padding-bottom:0px;overflow:auto;margin-bottom:var(--mbcbe4q-0);}@media (min-width:390px){.mbcbe4q{margin-bottom:72px;}}@media (min-width:460px){.mbcbe4q{padding-right:18px;margin-bottom:80px;}}@media (min-width:768px){.mbcbe4q{padding:20px;}}@media (min-width:1200px){.mbcbe4q{width:calc(100% - 24px);padding:20px 24px;margin-right:24px;}}
.t1lqnodp{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:50px;border-bottom:2px solid var(--horizontalLineColor);}@media (min-width:390px){.t1lqnodp{height:61px;padding:5px;}}@media (min-width:460px){.t1lqnodp{height:77px;padding:10px;}}
.p9b8f44{position:absolute;bottom:0;left:50%;width:100%;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:var(--modalBackgroundColor);box-shadow:0px -8px 12px var(--paginationShadowColor);border-bottom-right-radius:12px;border-bottom-left-radius:12px;}
.s1o1av0o{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:var(--backArrowIconColor);padding:15px;-webkit-transition:color 0.3s;transition:color 0.3s;}.s1o1av0o:hover{color:var(--closeIconHoverColor);}
.e1ifzpw9{position:relative;width:100%;height:100%;padding:40px 20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.e1xuz8q1{font-weight:700;font-size:16px;line-height:22px;text-align:center;color:var(--errorModalTextColor);margin-top:20px;margin-bottom:24px;}@media (min-width:390px){.e1xuz8q1{font-size:18px;line-height:25px;margin-top:24px;margin-bottom:32px;}}@media (min-width:460px){.e1xuz8q1{font-size:20px;line-height:28px;}}@media (min-width:768px){.e1xuz8q1{font-size:22px;line-height:26px;margin-top:32px;margin-bottom:40px;}}@media (min-width:1200px){.e1xuz8q1{font-size:24px;line-height:28px;}}
.skneaty{white-space:nowrap;}
.a1gphauc{position:absolute;top:5px;right:5px;}@media (min-width:460px){.a1gphauc{top:15px;right:10px;}}
.iv1pbo3{width:160px;height:142px;}@media (min-width:390px){.iv1pbo3{width:230px;height:204px;}}
